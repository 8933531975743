<template>
    <div class="library-layout">
        <projectIsUnderConstructionWarning style="position: relative; top: 60px; width: 100%;  z-index: 1;"></projectIsUnderConstructionWarning>
        <slot></slot>
    </div>
</template>

<script>
import projectIsUnderConstructionWarning from "@/components/projectIsUnderConstructionWarning.vue";

export default {
    name: "libraryLayout",
    components: {
        projectIsUnderConstructionWarning
    }
}
</script>